<template>
  <div class="grid md:grid-cols-4 sm:grid-cols-3 grid-cols-4 gap-4">
    <div class="sm:col-span-3 col-span-4">
      <shipblu-card :cardLoading="financialsLoader" class="pt-4" :class="accessFinancials ? '' : 'blurred-section'">
        <p class="px-5 font-medium text-primary text-xl mb-2">{{$t('Financial Center')}}</p>
        <div class="px-5 gap-2 justify-between sm:flex items-center">
          <div class="sm:px-2 w-full" style="max-width: 500px;">
            <div class="sm:p-2 py-2 border-dashed gap-x-2 border-b-1 border-t-0 border-r-0 border-l-0 border-grey font-medium flex border text-blue-100">
              <p>{{$t('Cash on the way')}}</p>
              <vx-tooltip style="color: #747474;" :text="`${$t('Cash on the way refers to the specific amount of money that will be transferred to you.')}`">
                <span class="material-icons text-sm cursor-pointer flex">info</span>
              </vx-tooltip>
              <div class="flex justify-between w-36 flex-shrink-0 ml-auto">
                <p>{{('EGP')}}</p>
                <p class="font-medium text-lg">{{financials.cash_on_the_way.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>     
              </div>
            </div>
            <div class="flex pt-3 sm:px-2 font-medium text-grey-dark gap-x-2">
              <p class="text-gray">{{$t('Cash Collection Balance')}}</p>
              <div class="flex justify-between w-36 flex-shrink-0 ml-auto">
                <p class="text-gray">{{('EGP')}}</p>
                <p>{{financials.cash_collection_balance.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
              </div>
            </div>
            <div class="flex pt-3 sm:px-2 font-medium text-grey-dark">
              <p class="text-gray">{{$t('Refunds In Progress')}}</p>
              <div class="flex justify-between w-36 flex-shrink-0 ml-auto">
                <p class="text-gray">{{('EGP')}}</p>
                <p>{{financials.refunds_in_progress.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
              </div>
            </div>
            <div class="flex pt-3 sm:px-2 font-medium text-grey-dark">
              <p class="text-gray">{{$t('Service Fees Due')}}</p>
              <div class="flex justify-between w-36 flex-shrink-0 ml-auto">
                <p class="text-gray">{{('EGP')}}</p>
                <p>{{financials.service_fees_due.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
              </div>
            </div>
            <div class="flex pt-3 sm:px-2 font-medium text-grey-dark">
              <p class="text-gray">{{$t('Amounts On Hold')}}</p>
              <div class="flex justify-between w-36 flex-shrink-0 ml-auto">
                <p class="text-gray">{{('EGP')}}</p>
                <p>{{financials.amounts_on_hold.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
              </div>
            </div>
          </div>
          <div class="sm:block hidden w-64">
            <img src="@/assets/images/pages/statment-pay.png" class="max-w-full" width="220">
          </div>
        </div>
        <div class="sm:flex mt-4 py-4 px-5 gap-2 items-center justify-between rounded-b-md bottom-card">
          <div class="flex items-center justify-between w-full sm:px-4" style="max-width: 500px;">
            <div class="font-medium text-grey-dark">
              <span class="font-semibold text-black">{{$t('Last Payout')}} </span>
              <span class="text-xs text-black">{{financials.last_payout_date ? `(${financials.last_payout_date}):` : `(YY-MM-DD):`}}</span>
            </div>
            <div class="flex justify-between w-36 ml-auto text-black font-semibold">
              <p>{{('EGP')}}</p>
              <p>{{financials.last_payout.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
          </div>
          <p class="cursor-pointer text-right sm:mt-0 mt-2 sm:w-64 underline font-medium text-blue-100" @click="accessFinancials ? $router.push(`/${$route.params.lang}/merchant/account?tab=overview`).catch(()=> {}) : ''">{{$t('Access Wallet')}}</p>
        </div>
      </shipblu-card>
    </div>
    <div class="md:col-span-1 col-span-4 grid md:grid-cols-1 grid-cols-2 gap-4">
      <shipblu-card :cardLoading="completedOrdersLoader" class="p-4">
        <p class="font-medium sm:text-base text-xl text-primary">{{$t('Pickups this month')}}</p>
        <p class="font-semibold sm:text-2xl text-xl text-center  mt-6 text-grey-dark">{{insightsData ? insightsData.pickups_this_month : 0}}</p>
      </shipblu-card>
      <shipblu-card :cardLoading="completedOrdersLoader" class="p-4 lg:relative">
        <p class="font-medium sm:text-base text-xl text-primary">{{$t('Returns this month')}}</p>
          <p class="font-semibold sm:text-2xl text-xl text-center mt-6 text-grey-dark">{{completedOrders.returned_order_count}}</p>
      </shipblu-card>
    </div>
  </div>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import { sendRequest } from '../../../http/axios/requestHelper'

export default {
  props: ['accessFinancials', 'insightsData'],
  data () {
    return {
      financials: {
        cash_collection_balance: 0,
        service_fees_due: 0,
        refunds_in_progress: 0,
        cash_on_the_way: 0,
        last_payout: 0,
        amounts_on_hold: 0,
        total_cod_collected: 0
      },
      completedOrders: {},
      financialsLoader: false,
      completedOrdersLoader: false
    }
  },
  methods: {
  
    loadFinancials () {
      this.financialsLoader = true
      sendRequest(true, false, this, 'api/v1/analytics/merchant/dashboard/financial-center/', 'get', null, true, 
        (response) => {
          this.financials = response.data
          this.financialsLoader = false
        }, () => {
          this.financialsLoader = false
        }
      )
    },
    loadCompletedOrders () {
      this.completedOrdersLoader = true
      sendRequest(true, false, this, 'api/v1/analytics/merchant/dashboard/completed-orders/', 'get', null, true, 
        (response) => {
          this.completedOrders = response.data
          this.completedOrdersLoader = false
        }
      )
    }
  },
  components: {
    ShipbluCard
  },
  created () {
    this.loadFinancials()
    this.loadCompletedOrders()
  }
}
</script>